$grid-breakpoints: (
  xs: 0,
  sm: 601px,
  md: 993px,
  lg: 1200px,
  xl: 1600px
);

$container-max-widths: (
  sm: 500px,
  md: 900px,
  lg: 1140px,
  xl: 1400px
);

// @each $bp in $grid-breakpoints {
    
// }

@mixin mediaMaxWidth($breakpoint) {
    @media (max-width: map-get($grid-breakpoints, $breakpoint)) {
        @content;
    }
}