@import "/src/styles/_breakpoints.scss";

.field {
    label {
        // padding: 24px 0 8px 52px;
        // @extend %typo-mons-h-16;
        // color: $color-gray-3;
        &.button-label {
            padding-left: 0;
        }
    }
    // span {
    //     color: $color-red;
    // }

    input[type="text"] {
        border: 2px solid #c7d6d9;
        box-sizing: border-box;
        height: 58px;
        padding: 16px;
        color: #3f4b4d;
        // }

        // input[type="text"] {
        // display: block;
        width: 100%;
        // width: calc(100% - 104px);
        // margin-left: 52px;
        // margin-bottom: 24px;
        // margin-top: 8px;
    }
    textarea {
        display: block;
        width: 100%;
        // width: calc(100% - 104px);
        // margin-left: 52px;
        height: 173px;
        // margin-bottom: 48px;
        // margin-top: 8px;
    }

    .warning {
        color: #ff9970;
        margin-top: 10px;
    }
}

@include mediaMaxWidth("sm") {
    .field {
        // margin-top: 10px;
        label {
            padding: 0 !important;
        }
        input[type="text"],
        textarea {
            // margin:0 0 15px 0 !important;
            margin: 0;
            width: 100% !important;
        }
    }
}
