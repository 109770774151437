$grid-breakpoints: (
  xs: 0,
  sm: 601px,
  md: 993px,
  lg: 1200px,
  xl: 1600px
);

@media (max-width: map-get($grid-breakpoints, 'sm')) {
    .feedback .button-blue {
        width: 100%;
    }
}

.load-resume img {
    margin-top: -10px;
}