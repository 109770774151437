.presentation .presentation-banner {
    position: absolute;
    // top: 278px;
    // left: 920px;
    // top: 120px;
    // left: 170px;
    right: 140px;
    width: 300px;
    height: 242px;
    min-width: 210px;
    min-height: 340px;
    z-index: 3;

    h1 {
        margin-left: 15px;
        text-align: left;
    }
    p.banner-text {
        display: block;
        text-align: left;
        margin: -15px 0 0 15px;
        width: 190px;
    }
    button {
        margin: 24px;
    }

    &__img {
        width: 100%;
        position: absolute;
        left: 0;
        z-index: -1;
    }

    &__img.sm_only {
        display: none;
    }

    &__title {
        padding-top: 60px;
        margin-top: 120px;
    }

    &__button {
        //   width: 270px;
        margin: 10px auto !important;
        width: 100%;
        & > a {
            color: #3f4b4d;
        }
    }
}

@media (max-width: map-get($grid-breakpoints, "sm")) {
    .presentation .presentation-banner {
        width: 100% !important;
        min-height: auto !important;

        // p.banner-text {
        //     text-align: center;
        //     margin: 0 auto;
        // }
        &__button {
            margin: 15px !important;
            width: calc(100% - 30px);
        }

        &__title {
            margin-top: 0;
            // margin-bottom: 0;
            padding-top: 70px !important;
            // text-align: center !important;
        }

        &__img:not(.sm_only) {
            display: none;
        }
    }
}

@media (max-width: map-get($grid-breakpoints, "md")) {
    .presentation .presentation-banner {
        width: 100% !important;
        top: 0;
        margin-left: 0 !important;
        left: 0 !important;
        bottom: 0;
        display: block;
        position: relative;
        margin-bottom: 0px !important;

        &__title {
            margin-top: 0 !important;
            // padding-top: 180px;
        }
    }
}
@media (max-width: map-get($grid-breakpoints, "lg")) {
    .presentation .presentation-banner {
        display: block;
        position: relative !important;
        width: 100%;
        text-align: center;
        left: 0;
        top: 0;
        // z-index: 2 !important;
        min-height: 340px;

        // &__button {
        //     width: auto !important;
        // }
        &__title {
            margin-top: 40px;
            padding-top: 180px;
        }
        img {
            z-index: 0;
            position: absolute;
            left: 0;
            top: 0;
        }
        h1,
        p,
        button {
            position: relative;
            z-index: 1;
        }
    }
}
