$grid-breakpoints: (
    xs: 0,
    sm: 601px,
    md: 993px,
    lg: 1200px,
    xl: 1600px,
);

@import "./presentationBanner";

.announce {
    .presentation {
        position: relative;
        &-card {
            // position: relative;
            // margin: 0 auto 52px 0;
            // max-width: 660px;
            // padding-top: 32px;

            h1 {
                display: block;
                max-width: 450px;
                text-align: left;
                //   margin: 0 auto 67px 0;
            }
        }
        &-info {
            text-align: left;
            max-width: 660px;
            box-sizing: border-box;
            padding: 32px;
            margin: 0;
            p {
                margin: 0;
            }
        }
        &-about-img {
            position: absolute;
            // left: 530px;
            right: 0;
            top: 0;
            width: 800px;
            z-index: -1;

            -webkit-filter: opacity(0.8);
            filter: opacity(0.8);
        }
    }
}

@media (max-width: 660px) {
    .presentation {
        &.container,
        .row,
        .col {
            margin: 0 !important;
            padding: 5px !important;
        }
    }
}

@media (max-width: map-get($grid-breakpoints, "sm")) {
    .presentation {
        .presentation-card {
            // margin: 0 auto 52px 16px !important;
            h1 {
                margin-left: 32px !important;
                margin-bottom: 48px !important;
                max-width: 300px !important;

                font-size: 24px !important;
            }
        }

        .presentation-banner {
            height: auto;
        }

        .presentation-banner__title {
            padding-top: 40px !important;
        }

        .presentation-about-img {
            // filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(257deg) brightness(105%) contrast(101%);

            // -webkit-filter: grayscale(.75);
            // filter: grayscale(.75);

            -webkit-filter: opacity(0.4);
            filter: opacity(0.4);
        }

        &.container,
        .row,
        .col {
            margin: 0 !important;
            padding: 0 !important;
        }
        &.container {
            width: 100% !important;
        }
    }

    .announce .pros p {
        margin-left: 15px;
    }
}

@media (max-width: map-get($grid-breakpoints, "md")) {
    .presentation {
        max-width: 100%;

        .presentation-card {
            margin: 0 !important;
        }
    }
}
@media (max-width: map-get($grid-breakpoints, "lg")) {
    .presentation {
        .presentation-info {
            max-width: none;
        }
        .presentation-about-img {
            left: 0;
            width: 100%;
        }
        .presentation-card {
            display: block;
            h1 {
                margin-left: 66px;
                margin-bottom: 48px !important;
                max-width: 300px !important;
            }
        }
    }
}
